import React, { useState } from 'react';
import styled from 'styled-components';
import logoData from '.././data/logoData.json';

const SelectorContainer = styled.div`
  margin: 20px;
`;

const FolderButton = styled.button`
  display: block;
  margin: 5px 0;
`;

const LogoOption = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const LogoImage = styled.img`
  width: 50px;
  margin-right: 10px;
`;

function LogoSelector({ onLogoSelect }) {
  const [currentPath, setCurrentPath] = useState([]);
  const [currentData, setCurrentData] = useState(logoData);

  const navigateToFolder = (folderName) => {
    setCurrentPath([...currentPath, folderName]);
    setCurrentData(currentData[folderName]);
  };

  const navigateBack = () => {
    const newPath = currentPath.slice(0, -1);
    let data = logoData;
    newPath.forEach((folder) => {
      data = data[folder];
    });
    setCurrentPath(newPath);
    setCurrentData(data);
  };

  const renderContent = () => {
    if (Array.isArray(currentData)) {
      // Display logos
      return currentData.map((logo) => (
        <LogoOption key={logo}>
          <LogoImage src={`/logos/${[...currentPath, logo].join('/')}`} alt={logo} />
          <button onClick={() => onLogoSelect('left', `/logos/${[...currentPath, logo].join('/')}`)}>
            Left Circle
          </button>
          <button onClick={() => onLogoSelect('right', `/logos/${[...currentPath, logo].join('/')}`)}>
            Right Circle
          </button>
        </LogoOption>
      ));
    } else {
      // Display folders
      return Object.keys(currentData).map((folderName) => (
        <FolderButton key={folderName} onClick={() => navigateToFolder(folderName)}>
          {folderName}
        </FolderButton>
      ));
    }
  };

  return (
    <SelectorContainer>
      {currentPath.length > 0 && <button onClick={navigateBack}>Back</button>}
      {renderContent()}
    </SelectorContainer>
  );
}

export default LogoSelector;
