import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const OverlayContainer = styled.div`
  text-align: center;
`;

const DownloadButton = styled.button`
  margin-top: 20px;
`;

function Overlay({ overlayImage, logos }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const loadImages = async () => {
      try {
        const overlayImg = await loadImage(overlayImage);

        // Set canvas dimensions
        canvas.width = overlayImg.width;
        canvas.height = overlayImg.height;

        // Draw the overlay image
        context.drawImage(overlayImg, 0, 0);

        // Desired maximum logo dimensions
        const maxLogoWidth = 400; // Adjust as needed
        const maxLogoHeight = 400; // Adjust as needed

        // Draw logos if they exist
        if (logos.left) {
          const leftLogoImg = await loadImage(logos.left);

          // Calculate scaled dimensions while maintaining aspect ratio
          const { width: logoWidth, height: logoHeight } = getScaledDimensions(
            leftLogoImg,
            maxLogoWidth,
            maxLogoHeight
          );

          // Adjust positions to center the logo over the circle
          const leftLogoX = overlayImg.width * 0.25 - logoWidth / 2;
          const leftLogoY = overlayImg.height * 0.48 - logoHeight / 2;

          context.drawImage(leftLogoImg, leftLogoX, leftLogoY, logoWidth, logoHeight);
        }

        if (logos.right) {
          const rightLogoImg = await loadImage(logos.right);

          // Calculate scaled dimensions while maintaining aspect ratio
          const { width: logoWidth, height: logoHeight } = getScaledDimensions(
            rightLogoImg,
            maxLogoWidth,
            maxLogoHeight
          );

          // Adjust positions to center the logo over the circle
          const rightLogoX = overlayImg.width * 0.75 - logoWidth / 2;
          const rightLogoY = overlayImg.height * 0.48 - logoHeight / 2;

          context.drawImage(rightLogoImg, rightLogoX, rightLogoY, logoWidth, logoHeight);
        }
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadImages();

    function loadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    }
  }, [overlayImage, logos]);

  function getScaledDimensions(img, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
    return {
      width: img.width * ratio,
      height: img.height * ratio,
    };
  }
  

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const link = document.createElement('a');
    link.download = 'composed-image.png';
    link.href = canvas.toDataURL('image/png');
    link.click();
  };

  return (
    <OverlayContainer>
      <canvas ref={canvasRef} style={{ maxWidth: '100%', height: 'auto' }} />
      <DownloadButton onClick={handleDownload}>Download Image</DownloadButton>
    </OverlayContainer>
  );
}

export default Overlay;
