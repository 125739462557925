import React, { useState } from 'react';
import Overlay from './components/Overlay';
import LogoSelector from './components/LogoSelector';

function App() {
  const [selectedLogos, setSelectedLogos] = useState({ left: null, right: null });

  const handleLogoSelect = (position, logoUrl) => {
    setSelectedLogos((prev) => ({ ...prev, [position]: logoUrl }));
  };

  return (
    <div className="App">
      <Overlay overlayImage="/overlay.jpg" logos={selectedLogos} />
      <LogoSelector onLogoSelect={handleLogoSelect} />
    </div>
  );
}

export default App;
